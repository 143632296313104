import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import { css } from "@emotion/react"
import { rhythm } from "../utils/typography"

export default function SiteLayout({ children }) {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  return (
    <div
      css={css`
        margin: ${rhythm(1)} auto;
        padding: ${rhythm(0.5)};
        max-width: 1024px;
      `}
    >
      <header
        css={css`
          display: flex;
          flex-wrap: wrap;
          margin-bottom: ${rhythm(0.5)};
        `}
      >
        <Link
          to="/"
          css={css`
            flex-grow: 1;
            margin-right: ${rhythm(1)};
          `}
        >
          <h2>{data.site.siteMetadata.title}</h2>
        </Link>
        <Navigation />
      </header>
      {children}
    </div>
  )
}

const Navigation = () => (
  <ul
    css={css`
      list-style: none;
      margin-left: 0;
      margin-bottom: ${rhythm(0.5)};
    `}
  >
    <ListLink to="/music-and-lyrics/">Music & Lyrics</ListLink>
    <ListLink to="/articles/">Articles</ListLink>
    <ListLink to="/links/">Links</ListLink>
    <ListLink to="/about/">About</ListLink>
  </ul>
)

const ListLink = ({ to, children }) => (
  <li
    css={css`
      display: inline-block;
      margin-right: ${rhythm(1)};
    `}
  >
    <Link to={to}>{children}</Link>
  </li>
)
